<template>
  <sgdf-responsive-header :username="`${user?.first_name} ${user?.last_name}`" :appRole="stageRole"
    :hasSearch="!!meetingId" :imageUrl="user?.avatar_url" mode="compact">
    <img src="@/assets/images/logo-sgdf-symbole.png" slot="logo" alt="logo" />

    <template v-if="isInsideMeeting">
      <NuxtLink :to="RouteUtils.getMeetingHome(meetingId!)">
        <sgdf-navbar-item>
          <sgdf-icon name="house-chimney" style="--icon-size: 1.5em"></sgdf-icon>
        </sgdf-navbar-item>
      </NuxtLink>
      <NuxtLink :to="hasPermission('view_event', meetingId)
        ? RouteUtils.getMeetingScheduleForFormers(meetingId!)
        : RouteUtils.getMeetingSchedule(meetingId!)
        " v-if="hasPermission('view_public_event', meetingId)">
        <sl-tooltip content="Planning de l'action de formation">
          <sgdf-navbar-item>
            {{ $t("label.schedule") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getDailyTasks(meetingId!)" v-if="hasPermission('view_dailytask', meetingId)">
        <sl-tooltip content="Services des prochains jours">
          <sgdf-navbar-item>
            {{ $t("label.daily_task") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingLinks(meetingId!)" v-if="hasPermission('view_public_resource', meetingId)">
        <sl-tooltip content="Base de connaissance">
          <sgdf-navbar-item>
            {{ $t("label.links") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingTeams(meetingId!)" v-if="hasPermission('view_team', meetingId)">
        <sl-tooltip content="Équipes du stage (équipes de vie et groupes de travail)">
          <sgdf-navbar-item>
            {{ $t("label.teams") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingParticipants(meetingId!)"
        v-if="hasPermission('view_meetingparticipant', meetingId)">
        <sl-tooltip content="Liste des stagiaires et leurs observations">
          <sgdf-navbar-item>
            {{ $t("label.interns") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
      <NuxtLink :to="`${RouteUtils.getMeetingParticipants(meetingId!)}?filter=check-in`" v-if="
        hasPermission('view_meetingparticipant', meetingId) && needCheckIn
      ">
        <sl-tooltip content="Émargement des stagiaires - disparaît quand tous les émargements ont été effectués">
          <sgdf-navbar-item>
            {{ $t("label.check-in") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingForms(meetingId!)"
        v-if="hasPermission('view_public_meetingform', meetingId) && hasModule('form', meetingId)">
        <sgdf-navbar-item>
          {{ $t("label.forms") }}
        </sgdf-navbar-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingParameters(meetingId!)" v-if="hasPermission('change_meeting', meetingId)">
        <sl-tooltip content="Edition et paramétrage du stage">
          <sgdf-navbar-item>
            {{ $t("label.parameters") }}
          </sgdf-navbar-item>
        </sl-tooltip>
      </NuxtLink>
    </template>
    <template v-else>
      <NuxtLink :to="RouteUtils.home">
        <sgdf-navbar-item>
          {{ $t("title.meetings.my") }}
        </sgdf-navbar-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.gestionnaire" v-if="hasPermission('view_all_meeting')">
        <sgdf-navbar-item>
          {{ $t("title.meetings.gest") }}
        </sgdf-navbar-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.gestionnaireMyScope" v-if="hasPermission('view_scope_meeting')">
        <sgdf-navbar-item>
          {{ $t("title.meetings.my-scope") }}
        </sgdf-navbar-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingGestionnaireOpinionReview()" v-if="hasPermission('view_scope_meeting')">
        <sgdf-navbar-item>
          {{ $t("title.meetings.opinion-review") }}
        </sgdf-navbar-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getGestionnaireParticipantsExport()"
        v-if="hasPermission('view_across_meetingparticipant')">
        <sgdf-navbar-item>
          {{ $t("title.export.participants") }}
        </sgdf-navbar-item>
      </NuxtLink>
    </template>
    <sl-menu slot="user-menu">
      <sl-menu-item @click="myDetails">{{
        $t("label.my-details")
        }}</sl-menu-item>
      <sl-menu-item @click="deontologie" v-if="stageRole?.isFormer">{{
        $t("label.deontologie")
        }}</sl-menu-item>
      <sl-menu-item @click="swapstage">{{
        $t("label.stage.swap")
        }}</sl-menu-item>
      <NuxtLink :to="RouteUtils.gestionnaire" v-if="hasPermission('view_all_meeting')">
        <sl-menu-item>
          {{ $t("title.meetings.gest") }}
        </sl-menu-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.gestionnaireMyScope" v-if="hasPermission('view_scope_meeting')">
        <sl-menu-item>
          {{ $t("title.meetings.my-scope") }}
        </sl-menu-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getMeetingGestionnaireOpinionReview()" v-if="hasPermission('view_scope_meeting')">
        <sl-menu-item>
          {{ $t("title.meetings.opinion-review") }}
        </sl-menu-item>
      </NuxtLink>
      <NuxtLink :to="RouteUtils.getGestionnaireParticipantsExport()"
        v-if="hasPermission('view_across_meetingparticipant')">
        <sl-menu-item>
          {{ $t("title.export.participants") }}
        </sl-menu-item>
      </NuxtLink>
      <sl-menu-item primary @click="logout">{{
        $t("label.logout")
        }}</sl-menu-item>
    </sl-menu>
    <sgdf-entities-search v-if="
      meetingId &&
      !(isDailyTaskTeamLoading && isParticipantLoading && isTeamsLoading) &&
      stageRole?.isFormer
    " :participants="participants?.results" :teams="teams?.results" :dailyTaskTeams="dailyTaskTeams?.results"
      slot="search" @sgdf-select-entity="select"></sgdf-entities-search>
  </sgdf-responsive-header>
</template>

<style scoped>
img {
  max-height: 100%;
  max-width: 160px;
  padding: 13px 5px 13px 13px;
  width: auto;
}
</style>

<script lang="ts" setup>
import "@sgdf/ui-library/sgdf-responsive-header.js";
import "@sgdf/ui-library/sgdf-entities-search.js";
import "@sgdf/ui-library/sgdf-navbar-item.js";
import "@shoelace-style/shoelace/dist/components/menu/menu.js";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item.js";
import { authenticationService } from "../core/services";
import { internFilter } from "~/core/utils/participant-filter";

const { data: user } = useMeDetails();

const hasPermission = usePermission();
const hasModule = useModule()

const userStore = useUserStore();

const meetingId = useMeetingId();

const stageRole = useRole();
const select = useNavigateToEntity();

const { data: participants, isLoading: isParticipantLoading } =
  useParticipants();
const { data: teams, isLoading: isTeamsLoading } = useTeams();
const { data: dailyTaskTeams, isLoading: isDailyTaskTeamLoading } =
  useDailyTaskTeams();

const route = useRoute();
const isInsideMeeting = computed(() => route.path.includes("/stages/"));
const needCheckIn = computed(() => {
  return (
    participants.value?.results
      ?.filter(internFilter)
      .some((p) => !p.signature_available && !p.hidden) || false
  );
});

async function logout() {
  authenticationService.getInstance().logout(meetingId.value!);
  userStore.$reset();
  await navigateTo(RouteUtils.login);
}

async function swapstage() {
  localStorage.removeItem("current_meeting");
  await navigateTo(RouteUtils.home);
}

async function deontologie() {
  await navigateTo(RouteUtils.deontology);
}

async function myDetails() {
  await navigateTo(RouteUtils.getMyDetails(meetingId.value!));
}
</script>
